import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import SettingsModal from './SettingsModal';
import {
  House,
  CameraVideo,
  Lightning,
  Collection,
  People,
  Upload,
  Grid,
  Grid3x3Gap,
  Hash,
  GraphUp,
  PeopleFill,
  Star,
  Gear,
  BoxArrowRight,
  ChevronDown,
  Search,
  Pencil,
  ChevronLeft,
  ChevronRight,
  ChatDots,
  Telephone
} from 'react-bootstrap-icons';
import './Sidebar.css';

const Sidebar = ({ isMobileOpen, onMobileClose, onSectionClick }) => {
  const { t } = useTranslation();
  const { signOut, isAuthenticated, user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [hasAutoCollapsed, setHasAutoCollapsed] = useState(false);
  const [hasAutoCollapsedSales, setHasAutoCollapsedSales] = useState(false);
  const isManualToggle = useRef(false);

  useEffect(() => {
    if (isManualToggle.current) {
      isManualToggle.current = false;
      return;
    }

    if (location.pathname.startsWith('/presales') && !hasAutoCollapsed) {
      setIsCollapsed(true);
      setHasAutoCollapsed(true);
      document.documentElement.style.setProperty('--sidebar-width', '80px');
    } else if (!location.pathname.startsWith('/presales')) {
      setHasAutoCollapsed(false);
    }

    if (location.pathname.startsWith('/sales') && !hasAutoCollapsedSales) {
      setIsCollapsed(true);
      setHasAutoCollapsedSales(true);
      document.documentElement.style.setProperty('--sidebar-width', '80px');
    } else if (!location.pathname.startsWith('/sales')) {
      setHasAutoCollapsedSales(false);
    }
  }, [location.pathname, hasAutoCollapsed, hasAutoCollapsedSales, isCollapsed]);

  useEffect(() => {
    if (isMobileOpen) {
      setIsCollapsed(false);
      document.documentElement.style.setProperty('--sidebar-width', '280px');
    }
  }, [isMobileOpen]);

  const handleSettingsClick = () => {
    setShowSettingsModal(true);
  };

  const handleCloseSettingsModal = () => {
    setShowSettingsModal(false);
  };

  const toggleSidebar = () => {
    isManualToggle.current = true;
    const newCollapsedState = !isCollapsed;
    
    setIsCollapsed(newCollapsedState);
    document.documentElement.style.setProperty(
      '--sidebar-width',
      newCollapsedState ? '80px' : '280px'
    );

    if (!newCollapsedState) {
      if (location.pathname.startsWith('/presales')) {
        setHasAutoCollapsed(false);
      }
      if (location.pathname.startsWith('/sales')) {
        setHasAutoCollapsedSales(false);
      }
    }
  };

  const handleSectionClick = (section) => {
    if (section === 'presales') {
      navigate('/presales/dashboard');
    } else if (section === 'sales') {
      navigate('/sales/dashboard');
    }
    if (onSectionClick) {
      onSectionClick(section);
    }
  };

  if (!isAuthenticated) return null;

  const isActive = (path) => location.pathname.startsWith(path);

  return (
    <>
      <div 
        className={`sidebar-backdrop ${isMobileOpen ? 'show' : ''}`}
        onClick={onMobileClose}
      />
      
      <div className={`sidebar ${isCollapsed ? 'collapsed' : ''} ${isMobileOpen ? 'mobile-open' : ''}`}>
        <div className="logo-section">
          <div className="logo-container">
            {!isCollapsed && <span className="logo-text">Selldrive<span className="ai-text">.ai</span></span>}
          </div>
          <button className="icon-button" onClick={toggleSidebar} aria-label="Toggle sidebar">
            {isCollapsed ? <ChevronRight size={18} /> : <ChevronLeft size={18} />}
          </button>
        </div>

        <nav className="d-flex flex-column h-100">
          <div className="nav-section">
            <Link 
              to="/chat" 
              className={`nav-link ${isActive('/chat') ? 'active' : ''} ${isCollapsed ? 'collapsed' : ''}`}
            >
              <House className="nav-icon" />
              {!isCollapsed && <span>Home</span>}
            </Link>

            <Link 
              to="/meetings" 
              className={`nav-link ${isActive('/meetings') ? 'active' : ''} ${isCollapsed ? 'collapsed' : ''}`}
            >
              <CameraVideo className="nav-icon" />
              {!isCollapsed && <span>Meetings</span>}
            </Link>

            <Link 
              to="/presales/dashboard"
              className={`nav-link ${isActive('/presales') ? 'active' : ''} ${isCollapsed ? 'collapsed' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                handleSectionClick('presales');
              }}
            >
              <ChatDots className="nav-icon" />
              {!isCollapsed && <span>{t('preSales')}</span>}
            </Link>

            <Link 
              to="/sales/dashboard"
              className={`nav-link ${isActive('/sales') ? 'active' : ''} ${isCollapsed ? 'collapsed' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                handleSectionClick('sales');
              }}
            >
              <Telephone className="nav-icon" />
              {!isCollapsed && <span>{t('sales')}</span>}
            </Link>

            <Link to="/contacts" className={`nav-link ${isActive('/contacts') ? 'active' : ''} ${isCollapsed ? 'collapsed' : ''}`}>
              <People className="nav-icon" />
              {!isCollapsed && <span>Contacts</span>}
            </Link>

            <Link to="/uploads" className={`nav-link ${isActive('/uploads') ? 'active' : ''} ${isCollapsed ? 'collapsed' : ''}`}>
              <Upload className="nav-icon" />
              {!isCollapsed && <span>Uploads</span>}
            </Link>
          </div>

          <div className="divider"></div>
          

          <div className="nav-section">
            <Link to="/integrations" className={`nav-link ${isActive('/integrations') ? 'active' : ''} ${isCollapsed ? 'collapsed' : ''}`}>
              <Grid className="nav-icon" />
              {!isCollapsed && <span>{t('connectApps')}</span>}
              <span className="nav-badge">NEW</span>
            </Link>

            <Link to="/prompts" className={`nav-link ${isActive('/prompts') ? 'active' : ''} ${isCollapsed ? 'collapsed' : ''}`}>
              <Grid3x3Gap className="nav-icon" />
              {!isCollapsed && <span>Prompts</span>}
              <span className="nav-badge">NEW</span>
            </Link>

            <Link to="/topic-tracker" className={`nav-link ${isActive('/topic-tracker') ? 'active' : ''} ${isCollapsed ? 'collapsed' : ''}`}>
              <Hash className="nav-icon" />
              {!isCollapsed && <span>Topic Tracker</span>}
            </Link>

            <Link to="/analytics" className={`nav-link ${isActive('/analytics') ? 'active' : ''} ${isCollapsed ? 'collapsed' : ''}`}>
              <GraphUp className="nav-icon" />
              {!isCollapsed && <span>{t('dashboard')}</span>}
            </Link>
          </div>

          <div className="divider mt-auto"></div>

          <div className="nav-section">
            <Link to="/team" className={`nav-link ${isActive('/team') ? 'active' : ''} ${isCollapsed ? 'collapsed' : ''}`}>
              <PeopleFill className="nav-icon" />
              {!isCollapsed && <span>Team</span>}
            </Link>

            <Link to="/upgrade" className={`nav-link ${isActive('/upgrade') ? 'active' : ''} ${isCollapsed ? 'collapsed' : ''}`}>
              <Star className="nav-icon" />
              {!isCollapsed && <span>Upgrade</span>}
            </Link>
          </div>

          <div className="divider mt-auto"></div>

          <div className="user-section">
            <div className="dropdown">
              <button
                className="user-dropdown-toggle"
                id="dropdownUser1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src={user?.imageUrl || '/default-avatar.png'}
                  alt={user?.username}
                  className="user-avatar"
                />
                {!isCollapsed && (
                  <>
                    <span className="user-name">{user?.username}</span>
                    <ChevronDown className="ms-auto" />
                  </>
                )}
              </button>
              <ul
                className="dropdown-menu dropdown-menu-dark"
                aria-labelledby="dropdownUser1"
              >
                <li>
                  <button className="dropdown-item" onClick={handleSettingsClick}>
                    <Gear className="me-2" />
                    {t('settings')}
                  </button>
                </li>
                <li><hr className="dropdown-divider" /></li>
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      signOut();
                      navigate('/login');
                    }}
                  >
                    <BoxArrowRight className="me-2" />
                    {t('signOut')}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>

      <SettingsModal
        show={showSettingsModal}
        handleClose={handleCloseSettingsModal}
      />
    </>
  );
};

export default Sidebar;
