import { useContext, useEffect, useCallback } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';

export function useBlocker(blocker, when = true) {
  const { navigator } = useContext(UNSAFE_NavigationContext);

  useEffect(() => {
    if (!when) return;

    const push = navigator.push;
    navigator.push = (...args) => {
      const allowNavigation = blocker();
      if (allowNavigation !== false) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, blocker, when]);
}

export function usePrompt(message, when = true, onConfirm) {
  const blocker = useCallback(() => {
    const confirmLeave = window.confirm(message);
    if (confirmLeave && onConfirm) {
      onConfirm();
    }
    return confirmLeave;
  }, [message, onConfirm]);

  useBlocker(blocker, when);
} 