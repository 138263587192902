import React from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import './NotFoundPage.css'; // Import the CSS file

const NotFoundPage = () => {
  const { t } = useTranslation(); // Initialize useTranslation

  return (
    <div id="NotFoundPage" className="text-center">
      <main className="form-signin">
          <img className="mb-4" src="https://getbootstrap.com/docs/5.0/assets/brand/bootstrap-logo.svg" alt="" width="72" height="57" />
          <h1 className="h3 mb-3 fw-normal">{t('notFoundPageTitle')}</h1>
      </main>
    </div>
  );
};

export default NotFoundPage;
