import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const SettingsModal = ({ show, handleClose }) => {
  const { i18n, t } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [apiKey, setApiKey] = useState(localStorage.getItem('openAiApiKey') || '');

  const handleSaveSettings = () => {
    // Save language setting
    i18n.changeLanguage(language);

    // Save API key to localStorage
    localStorage.setItem('openAiApiKey', apiKey);

    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className="border-0">
        <Modal.Title>{t('settings')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="mb-3">
            <label htmlFor="languageSelect" className="form-label fw-semibold">
              {t('language')}
            </label>
            <select
              id="languageSelect"
              className="form-select"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <option value="en">English</option>
              <option value="es">Español</option>
              <option value="pt">Português</option>
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="apiKey" className="form-label fw-semibold">
              {t('openAiApiKey')}
            </label>
            <input
              type="password"
              className="form-control"
              id="apiKey"
              placeholder={t('enterApiKey')}
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleClose}
        >
          {t('close')}
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleSaveSettings}
        >
          {t('saveChanges')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SettingsModal;
