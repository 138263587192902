import React from 'react';
import { useTranslation } from 'react-i18next';
import SalesSecondaryNav from './SalesSecondaryNav';
import './SalesDashboard.css';

const SalesDashboard = () => {
  const { t } = useTranslation();

  return (
    <div id="SalesDashboard" className="d-flex h-100">
      <SalesSecondaryNav />
      <div className="sales-content container-fluid p-4">
        <div className="row">
          <div className="col-12">
            <h2 className="mb-4">Dashboard Vendas</h2>
            <div className="dashboard-iframe-container">
              <iframe 
                src="https://lookerstudio.google.com/embed/reporting/e8e0ef5a-9383-4d91-8d88-59ce1cc80a72/page/p_m7t5k689md" 
                frameBorder="0" 
                style={{ border: 0 }} 
                allowFullScreen
                sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesDashboard; 