import React, { createContext, useState, useContext, useEffect } from 'react';
import { 
  signInWithPopup, 
  GoogleAuthProvider,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  getAuth,
} from 'firebase/auth';
import { auth } from '../config/firebase';

const googleProvider = new GoogleAuthProvider();
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [user, setUser] = useState({ username: '', imageUrl: '', token: null });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const token = await currentUser.getIdToken(true);
        setIsAuthenticated(true);
        setUser({
          username: currentUser.displayName || currentUser.email.split('@')[0],
          imageUrl: currentUser.photoURL || 'https://github.com/mdo.png',
          token: token,
        });
      } else {
        setIsAuthenticated(false);
        setUser({ username: '', imageUrl: '', token: null });
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const firebaseUser = result.user;
      const token = await firebaseUser.getIdToken();
      setUser({
        username: firebaseUser.displayName || firebaseUser.email.split('@')[0],
        imageUrl: firebaseUser.photoURL || 'https://github.com/mdo.png',
        token: token,
      });
      setIsAuthenticated(true);
      return firebaseUser;
    } catch (error) {
      console.error('Error signing in with Google:', error);
      throw error;
    }
  };

  const signOut = async () => {
    try {
      await firebaseSignOut(auth);
      setIsAuthenticated(false);
      setUser({ username: '', imageUrl: '', token: null });
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  if (loading) {
    return null;
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, signInWithGoogle, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
