import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../contexts/AuthContext';
import { PromptContext } from '../contexts/PromptContext';

const PromptEditor = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const [promptData, setPromptData] = useState({
    title: '',
    description: '',
    webhookUrl: '',
    webhookFields: '',
    organizationId: '',
    isArchived: false,
  });
  const [error, setError] = useState('');
  const { getPrompt, createPrompt, updatePrompt } = useContext(PromptContext);

  useEffect(() => {
    const fetchPrompt = async () => {
      if (!user.token) {
        console.error('User is not authenticated.');
        return;
      }

      try {
        const response = await getPrompt(id);
        setPromptData(response);
      } catch (error) {
        console.error('Error fetching prompt:', error);
        setError('Failed to fetch prompt. Please try again.');
      }
    };

    if (id && id !== 'new') {
      fetchPrompt();
    }
  }, [id, getPrompt, user.token]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPromptData({
      ...promptData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSave = async () => {
    setError('');
    if (!promptData.title || !promptData.description || !promptData.webhookUrl) {
      setError(t('pleaseFillAllFields'));
      return;
    }

    try {
      if (!id || id === 'new') {
        await createPrompt(promptData);
      } else {
        await updatePrompt(id, promptData);
      }
      navigate('/prompts');
    } catch (error) {
      console.error('Error saving prompt:', error);
      setError(t('errorSavingPrompt'));
    }
  };

  return (
    <div id="PromptEditor" className="container-fluid p-4">
      <div className="row">
        <div className="col-12 col-lg-8">
          <div className="card shadow-sm">
            <div className="card-body">
              <h2 className="card-title mb-4">
                {id === 'new' ? t('addPrompt') : t('editPrompt')}
              </h2>

              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}

              <form>
                <div className="mb-3">
                  <label htmlFor="promptTitle" className="form-label fw-semibold">
                    {t('title')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="promptTitle"
                    name="title"
                    value={promptData.title}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="promptDescription" className="form-label fw-semibold">
                    {t('description')}
                  </label>
                  <textarea
                    className="form-control"
                    id="promptDescription"
                    name="description"
                    rows="5"
                    value={promptData.description}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="webhookUrl" className="form-label fw-semibold">
                    {t('webhookUrl')}
                  </label>
                  <input
                    type="url"
                    className="form-control"
                    id="webhookUrl"
                    name="webhookUrl"
                    value={promptData.webhookUrl}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="webhookFields" className="form-label fw-semibold">
                    {t('webhookFields')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="webhookFields"
                    name="webhookFields"
                    value={promptData.webhookFields}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="isArchived"
                    name="isArchived"
                    checked={promptData.isArchived}
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="isArchived">
                    {t('archivePrompt')}
                  </label>
                </div>

                <div className="d-flex gap-2">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSave}
                  >
                    {t('save')}
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => navigate('/prompts')}
                  >
                    {t('cancel')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromptEditor;
