import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PromptContext } from '../contexts/PromptContext';

const PromptList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    prompts,
    loading,
    error,
    fetchPrompts,
    deletePrompt,
  } = useContext(PromptContext);
  
  const [searchQuery, setSearchQuery] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  useEffect(() => {
    fetchPrompts(currentPage, searchTerm);
  }, [currentPage, searchTerm, fetchPrompts]);

  const handleAddPrompt = () => {
    navigate('/prompts/new');
  };

  const handleEditClick = (id) => {
    navigate(`/prompts/${id}`);
  };

  const handleDeletePrompt = async (id) => {
    if (window.confirm(t('areYouSureYouWantToDelete'))) {
      try {
        await deletePrompt(id);
      } catch (error) {
        console.error('Error deleting prompt:', error);
      }
    }
  };

  const handleSearchClick = () => {
    setSearchTerm(searchQuery);
    setCurrentPage(1);
  };

  return (
    <div id="PromptList" className="d-flex h-100">
      <div className="prompt-content container-fluid p-4">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div>
            <h2>{t('prompts')}</h2>
            <p className="text-muted mb-0">{t('promptListDescription')}</p>
          </div>
          <button className="btn btn-primary" onClick={handleAddPrompt}>
            {t('addPrompt')}
          </button>
        </div>

        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}

        <div className="mb-3 d-flex">
          <input
            type="text"
            className="form-control w-auto me-2"
            placeholder={t('search')}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button className="btn btn-outline-primary" onClick={handleSearchClick}>
            {t('search')}
          </button>
        </div>

        <div className="card shadow-sm">
          <div className="table-responsive">
            <table className="table table-hover mb-0">
              <thead className="table-light">
                <tr>
                  <th>{t('title')}</th>
                  <th className="d-none d-md-table-cell">{t('description')}</th> {/* Hidden on small screens */}
                  <th className="d-none d-lg-table-cell">{t('webhookUrl')}</th> {/* Hidden on small and medium screens */}
                  <th>{t('actions')}</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="4" className="text-center py-4">
                      <div className="spinner-border" style={{ color: 'var(--primary-color)' }} role="status">
                        <span className="visually-hidden">{t('loading')}</span>
                      </div>
                    </td>
                  </tr>
                ) : Array.isArray(prompts) && prompts.length > 0 ? (
                  prompts.map((prompt) => (
                    <tr key={prompt.id}>
                      <td>{prompt.title}</td>
                      <td className="d-none d-md-table-cell">
                        <div className="text-truncate" style={{ maxWidth: '300px' }}>
                          {prompt.description}
                        </div>
                      </td>
                      <td className="d-none d-lg-table-cell">
                        <div className="text-truncate" style={{ maxWidth: '200px' }}>
                          {prompt.webhookUrl}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex gap-2">
                          <button
                            onClick={() => handleEditClick(prompt.id)}
                            className="btn btn-link p-0"
                          >
                            {t('edit')}
                          </button>
                          <button
                            onClick={() => handleDeletePrompt(prompt.id)}
                            className="btn btn-link text-danger p-0"
                          >
                            {t('delete')}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center py-4">
                      {t('noPromptsFound')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="card-footer bg-white">
            <nav aria-label="Page navigation">
              <ul className="pagination justify-content-center mb-0">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <button
                    className="page-link"
                    onClick={() => setCurrentPage((prev) => prev - 1)}
                  >
                    {t('previous')}
                  </button>
                </li>
                <li className="page-item active">
                  <span className="page-link">
                    {t('page')} {currentPage}
                  </span>
                </li>
                <li className={`page-item ${prompts.length < pageSize ? 'disabled' : ''}`}>
                  <button
                    className="page-link"
                    onClick={() => setCurrentPage((prev) => prev + 1)}
                  >
                    {t('next')}
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      {/* If you have a sidebar component for Prompts, include it here */}
      {/* <div className="prompt-sidebar">
        <PromptSidebar />
      </div> */}
    </div>
  );
};

export default PromptList;
