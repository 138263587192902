import React from 'react';
import { Link } from 'react-router-dom';
import { List, ChatDots } from 'react-bootstrap-icons';
import { Navbar, Container } from 'react-bootstrap';
import './TopMenu.css';
// import ChatPage from './ChatPage';

const TopMenu = ({ onMenuClick }) => {
  return (
    <Navbar bg="dark" variant="dark" className="mobile-top-menu">
      <Container fluid className="px-3">
        <button 
          className="menu-button"
          onClick={onMenuClick}
          aria-label="Menu"
        >
          <List size={24} />
        </button>

        <Navbar.Brand className="mx-auto">
          Selldrive<span className="text-primary">.ai</span>
        </Navbar.Brand>

        <Link to="/chat" className="chat-button">
          <ChatDots size={24} />
        </Link>
      </Container>
    </Navbar>
  );
};

export default TopMenu; 