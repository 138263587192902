// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import LoginPage from './components/LoginPage';
import PromptList from './components/PromptList';
import PromptEditor from './components/PromptEditor';
import NotFoundPage from './components/NotFoundPage';
import ProtectedRoute from './components/ProtectedRoute';
import './App.css';
import LanguageSwitcher from './components/LanguageSwitcher';
import RegisterPage from './components/RegisterPage';
import ForgotPasswordPage from './components/ForgotPasswordPage';
import PresalesList from './components/PresalesList';
import PresalesEditor from './components/PresalesEditor';
import SalesList from './components/SalesList';
import SalesEditor from './components/SalesEditor';
import { PresalesProvider } from './contexts/PresalesContext';
import { SalesProvider } from './contexts/SalesContext';
import { PromptProvider } from './contexts/PromptContext';
import { MicrophoneProvider } from './contexts/MicrophoneContext';
import TopMenu from './components/TopMenu';
import Sidebar from './components/Sidebar';
import ChatPage from './components/ChatPage';
import PresalesSecondaryNav from './components/PresalesSecondaryNav';
import PresalesDashboard from './components/PresalesDashboard';
import SalesSecondaryNav from './components/SalesSecondaryNav';
import SalesDashboard from './components/SalesDashboard';

// Add this new component for handling auth redirects
const AuthRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  
  if (isAuthenticated) {
    return <Navigate to="/chat" replace />;
  }

  return children;
};

const AppContent = () => {
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [showSecondaryNav, setShowSecondaryNav] = useState(false);
  const location = useLocation();

  const handleMobileMenuClick = () => {
    // If in sales or presales route, show secondary nav first
    if (location.pathname.startsWith('/presales') || location.pathname.startsWith('/sales')) {
      setShowSecondaryNav(true);
    } else {
      setIsMobileSidebarOpen(true);
    }
  };

  const handleMobileSidebarClose = () => {
    setIsMobileSidebarOpen(false);
    setShowSecondaryNav(false);
  };

  const handleBackToSidebar = () => {
    setShowSecondaryNav(false);
    setIsMobileSidebarOpen(true);
  };

  const handleSectionClick = (section) => {
    setIsMobileSidebarOpen(false);
    if (section === 'presales' || section === 'sales') {
      setShowSecondaryNav(true);
    }
  };

  return (
    <div id="App">
      <LanguageSwitcher />
      
      {/* TopMenu visible only on mobile when authenticated */}
      <div className="d-lg-none">
        <TopMenu onMenuClick={handleMobileMenuClick} />
      </div>
      <div className="d-flex">
        <Sidebar 
          isMobileOpen={isMobileSidebarOpen}
          onMobileClose={handleMobileSidebarClose}
          onSectionClick={handleSectionClick}
        />
        
        {/* Show appropriate secondary nav based on route */}
        <div className="d-lg-none">
          {location.pathname.startsWith('/presales') && (
            <PresalesSecondaryNav 
              isMobileOpen={showSecondaryNav}
              onMobileClose={handleMobileSidebarClose}
              onBackClick={handleBackToSidebar}
            />
          )}
          {location.pathname.startsWith('/sales') && (
            <SalesSecondaryNav 
              isMobileOpen={showSecondaryNav}
              onMobileClose={handleMobileSidebarClose}
              onBackClick={handleBackToSidebar}
            />
          )}
        </div>

        <div className="main-content">
          <PresalesProvider>
            <SalesProvider>
              <PromptProvider>
                <MicrophoneProvider>
                  <Routes>
                    {/* Wrap auth routes with AuthRoute component */}
                    <Route path="/login" element={
                      <AuthRoute>
                        <LoginPage />
                      </AuthRoute>
                    } />
                    <Route path="/register" element={
                      <AuthRoute>
                        <RegisterPage />
                      </AuthRoute>
                    } />
                    <Route path="/forgot-password" element={
                      <AuthRoute>
                        <ForgotPasswordPage />
                      </AuthRoute>
                    } />
                    <Route
                      path="/prompts/new"
                      element={
                        <ProtectedRoute>
                          <PromptEditor />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/prompts/:id"
                      element={
                        <ProtectedRoute>
                          <PromptEditor />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/prompts"
                      element={
                        <ProtectedRoute>
                          <PromptList />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/presales/new"
                      element={
                        <ProtectedRoute>
                          <PresalesEditor />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/presales/:id"
                      element={
                        <ProtectedRoute>
                          <PresalesEditor />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/presales"
                      element={
                        <ProtectedRoute>
                          <PresalesList />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/presales/my-meetings"
                      element={
                        <ProtectedRoute>
                          <PresalesList />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/presales/all-meetings"
                      element={
                        <ProtectedRoute>
                          <PresalesList />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/presales/shared"
                      element={
                        <ProtectedRoute>
                          <PresalesList />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/sales/new"
                      element={
                        <ProtectedRoute>
                          <SalesEditor />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/sales/:id"
                      element={
                        <ProtectedRoute>
                          <SalesEditor />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/sales"
                      element={<Navigate to="/sales/dashboard" replace />}
                    />
                    <Route
                      path="/sales/dashboard"
                      element={
                        <ProtectedRoute>
                          <SalesDashboard />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/sales/my-calls"
                      element={
                        <ProtectedRoute>
                          <SalesList />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/sales/all-calls"
                      element={
                        <ProtectedRoute>
                          <SalesList />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/sales/shared"
                      element={
                        <ProtectedRoute>
                          <SalesList />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/chat"
                      element={
                        <ProtectedRoute>
                          <ChatPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/presales/dashboard"
                      element={
                        <ProtectedRoute>
                          <PresalesDashboard />
                        </ProtectedRoute>
                      }
                    />
                    <Route path="*" element={<NotFoundPage />} />
                    <Route path="/" element={<Navigate to="/chat" replace />} />
                  </Routes>
                </MicrophoneProvider>
              </PromptProvider>
            </SalesProvider>
          </PresalesProvider>
        </div>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
};

export default App;
