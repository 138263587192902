import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SalesContext } from '../contexts/SalesContext';
import { PromptContext } from '../contexts/PromptContext';
import { useMicrophone } from '../contexts/MicrophoneContext';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { Spinner } from 'react-bootstrap';
import { usePrompt } from '../utils/hooks';

const INITIAL_SESSION_DATA = {
  sessionSource: '',
  sessionDestination: '',
  sessionDuration: '',
  promptId: '',
  isTest: false,
};

const SalesSidebar = () => {
  const { t } = useTranslation();
  const { createSession } = useContext(SalesContext);
  const { prompts, fetchPrompts, loading: promptsLoading } = useContext(PromptContext);
  const { user } = useAuth();

  const {
    startRecording,
    stopRecording,
    pauseRecording,
    muteMicrophone,
    setAudioDataCallback,
    setOnStopCallback,
  } = useMicrophone();

  // State variables
  const [status, setStatus] = useState('idle'); // 'idle', 'form', 'recording', 'uploading'
  const [sessionData, setSessionData] = useState(INITIAL_SESSION_DATA);
  const [errors, setErrors] = useState({}); // For validation errors

  // Use useRef to store sessionId
  const sessionIdRef = useRef(null);

  // Add a state variable and ref for recording duration
  const [recordingDuration, setRecordingDuration] = useState(0);
  const recordingIntervalRef = useRef(null);

  // Fetch prompts when the component mounts
  useEffect(() => {
    if (status === 'form') {
      fetchPrompts();
    }
  }, [status, fetchPrompts]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSessionData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '', // Clear error message when user types
    }));
  };

  // Validation function
  const validateForm = () => {
    const newErrors = {};
    if (!sessionData.sessionSource.trim()) {
      newErrors.sessionSource = t('pleaseEnterSessionSource');
    }
    if (!sessionData.sessionDestination.trim()) {
      newErrors.sessionDestination = t('pleaseEnterSessionDestination');
    }
    if (!sessionData.sessionDuration.trim()) {
      newErrors.sessionDuration = t('pleaseEnterSessionAmount');
    }
    if (!sessionData.promptId) {
      newErrors.promptId = t('pleaseSelectPrompt');
    }
    return newErrors;
  };

  // Click handlers
  const handleNewSaleClick = () => {
    setStatus('form');
  };

  const sendAudioChunk = useCallback(async (sessionId, audioBlob) => {
    if (!sessionId) {
      console.error('No session ID available');
      return;
    }

    if (!user?.token) {
      console.error('User is not authenticated');
      return;
    }

    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/sessions/${sessionId}/audio`;

      // Generate a timestamp or unique identifier for the chunk
      const timestamp = Date.now().toString();

      await axios.put(url, audioBlob, {
        headers: {
          'Content-Type': 'audio/webm',
          'x-timestamp': timestamp,
          Authorization: `Bearer ${user.token}`,
        },
      });

      console.log('Audio chunk sent successfully');
    } catch (error) {
      console.error('Error sending audio chunk:', error);
    }
  }, [user?.token]);

  const handleAudioData = useCallback(async (audioBlob) => {
    const sessionId = sessionIdRef.current;
    if (!sessionId) {
      console.error('No session ID available');
      return;
    }
    await sendAudioChunk(sessionId, audioBlob);
  }, [sendAudioChunk]);

  const sendAudioEndSignal = useCallback(async (sessionId, duration) => {
    if (!user?.token) {
      throw new Error('User not authenticated');
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/sessions/${sessionId}/audio-end`,
        { sessionDuration: duration }, // Include sessionDuration in the request body
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      console.log('Audio-end signal sent successfully');
      return response.data;
    } catch (error) {
      console.error('Error sending audio-end signal:', error);
      throw error;
    }
  }, [user?.token]);

  const resetSale = useCallback(() => {
    sessionIdRef.current = null;
    setStatus('idle');
    setSessionData(INITIAL_SESSION_DATA);
    setErrors({});
  }, []);

  const handleRecordingStop = useCallback(async () => {
    try {
      const sessionId = sessionIdRef.current;
      if (!sessionId) {
        throw new Error('No session ID available to signal audio end');
      }

      // Stop the recording duration timer
      if (recordingIntervalRef.current) {
        clearInterval(recordingIntervalRef.current);
        recordingIntervalRef.current = null;
      }

      setStatus('uploading');
      // Send the recording duration to the backend
      await sendAudioEndSignal(sessionId, recordingDuration);

      // Reset the sale data and UI
      resetSale();
      // Set status back to idle after successful upload
      setStatus('idle');
    } catch (error) {
      console.error('Error during recording stop process:', error);
      setStatus('idle');
    }
  }, [sendAudioEndSignal, resetSale, recordingDuration]);

  useEffect(() => {
    if (status === 'recording') {
      setAudioDataCallback(handleAudioData);
      setOnStopCallback(handleRecordingStop);
    }
  }, [handleAudioData, handleRecordingStop, setAudioDataCallback, setOnStopCallback, status]);

  const handleStartRecording = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const newSessionId = await createSession({
        ...sessionData,
        sessionType: 'sales', // Include sessionType to differentiate session types
      });
      sessionIdRef.current = newSessionId;
      setStatus('recording');

      // Start tracking recording duration
      setRecordingDuration(0);
      recordingIntervalRef.current = setInterval(() => {
        setRecordingDuration((prevDuration) => prevDuration + 1);
      }, 1000); // Increment every second

      await startRecording();
    } catch (error) {
      console.error('Error starting recording:', error);
      setStatus('idle');
    }
  };

  // Update handleStopRecording to only call stopRecording()
  const handleStopRecording = () => {
    console.log('Stopping recording');
    stopRecording();
    // Do not set status here; it will be updated in handleRecordingStop
  };

  const handlePause = () => {
    pauseRecording();
  };

  const handleMute = () => {
    muteMicrophone(true); // Pass true to mute the microphone
  };

  const handleCancel = () => {
    setStatus('idle');
    setSessionData(INITIAL_SESSION_DATA);
    setErrors({});
  };

  // Prompt the user when they try to navigate away during recording
  usePrompt(t('confirmations.areYouSureYouWantToLeave'), status === 'recording', () => {
    stopRecording();
  });

  // Warn the user before closing the tab/window during recording
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (status === 'recording') {
        stopRecording();
        event.preventDefault();
        event.returnValue = ''; // Required for some browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [status, stopRecording]);

  return (
    <aside className="bg-light border-start h-100 overflow-auto">
      <div className="p-4">
        {status === 'idle' && (
          <>
            <h2 className="h5 pb-3 mb-4 border-bottom">
              {t('saleConfiguration')}
            </h2>
            <button
              className="btn btn-primary w-100"
              onClick={handleNewSaleClick}
            >
              {t('newSale')}
            </button>
          </>
        )}

        {status === 'form' && (
          <>
            <h2 className="h5 pb-3 mb-4 border-bottom">{t('newSale')}</h2>
            <form>
              <div className="mb-3">
                <label htmlFor="sessionSource" className="form-label">
                  {t('sessionSource')}
                </label>
                <input
                  type="text"
                  className={`form-control ${errors.sessionSource ? 'is-invalid' : ''}`}
                  id="sessionSource"
                  name="sessionSource"
                  value={sessionData.sessionSource}
                  onChange={handleChange}
                />
                {errors.sessionSource && (
                  <div className="invalid-feedback">{errors.sessionSource}</div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="sessionDestination" className="form-label">
                  {t('sessionDestination')}
                </label>
                <input
                  type="text"
                  className={`form-control ${errors.sessionDestination ? 'is-invalid' : ''}`}
                  id="sessionDestination"
                  name="sessionDestination"
                  value={sessionData.sessionDestination}
                  onChange={handleChange}
                />
                {errors.sessionDestination && (
                  <div className="invalid-feedback">{errors.sessionDestination}</div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="sessionDuration" className="form-label">
                  {t('sessionDuration')}
                </label>
                <input
                  type="text"
                  className={`form-control ${errors.sessionDuration ? 'is-invalid' : ''}`}
                  id="sessionDuration"
                  name="sessionDuration"
                  value={sessionData.sessionDuration}
                  onChange={handleChange}
                />
                {errors.sessionDuration && (
                  <div className="invalid-feedback">{errors.sessionDuration}</div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="promptId" className="form-label">
                  {t('aiPrompt')}
                </label>
                {promptsLoading ? (
                  <div className="form-control">{t('loading')}...</div>
                ) : (
                  <select
                    className={`form-select ${errors.promptId ? 'is-invalid' : ''}`}
                    id="promptId"
                    name="promptId"
                    value={sessionData.promptId}
                    onChange={handleChange}
                  >
                    <option value="">{t('selectPrompt')}</option>
                    {prompts.map((prompt) => (
                      <option key={prompt.id} value={prompt.id}>
                        {prompt.title}
                      </option>
                    ))}
                  </select>
                )}
                {errors.promptId && (
                  <div className="invalid-feedback">{errors.promptId}</div>
                )}
              </div>
              <div className="mb-3 form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="isTest"
                  name="isTest"
                  checked={sessionData.isTest}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="isTest">
                  {t('isTest')}
                </label>
              </div>
              <div className="d-flex gap-2">
                <button
                  type="button"
                  className="btn btn-success flex-grow-1"
                  onClick={handleStartRecording}
                >
                  {t('startRecording')}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary flex-grow-1"
                  onClick={handleCancel}
                >
                  {t('cancel')}
                </button>
              </div>
            </form>
          </>
        )}

        {status === 'recording' && (
          <>
            <h2 className="h5 pb-3 mb-4 border-bottom">{t('recording')}</h2>
            <div className="text-center my-4">
              <p className="fs-5 fw-bold">{t('recordingPlaceholderText')}</p>
              <div className="btn-group w-100">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleStopRecording}
                >
                  {t('stop')}
                </button>
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={handlePause}
                >
                  {t('pause')}
                </button>
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={handleMute}
                >
                  {t('mute')}
                </button>
              </div>
            </div>
          </>
        )}

        {status === 'uploading' && (
          <>
            <h2 className="h5 pb-3 mb-4 border-bottom">
              {t('uploadingRecording')}
            </h2>
            <div className="d-flex flex-column align-items-center mt-4">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">{t('loading')}</span>
              </div>
              <span className="mt-3">{t('uploadingRecording')}</span>
            </div>
          </>
        )}
      </div>
    </aside>
  );
};

export default SalesSidebar; 