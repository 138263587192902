import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PresalesContext } from '../contexts/PresalesContext';
import { PromptContext } from '../contexts/PromptContext';
import { 
  ChevronLeft, PlayFill, PauseFill, ArrowCounterclockwise, 
  ArrowClockwise, Download, Save, Archive, Trash, ArrowRepeat, Robot, Send 
} from 'react-bootstrap-icons';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import './PresalesEditor.css';

// Utility functions
const formatTime = (time) => {
  if (!time) return '00:00';
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

// Sub-components
const AudioControls = ({ 
  isPlaying, canPlay, togglePlay, skipBackward, skipForward, 
  currentTime, duration, handleProgress, playbackRate, 
  handlePlaybackRate, handleDownload 
}) => (
  <div className="audio-controls">
    <span className="time-display">
      <span className="time-display-mobile">{formatTime(currentTime)}</span>
      <span className="time-display-desktop">
        {formatTime(currentTime)} / {formatTime(duration)}
      </span>
    </span>

    <button 
      className="btn btn-light rounded-circle d-flex align-items-center justify-content-center p-2"
      onClick={skipBackward}
      disabled={!canPlay}
    >
      <ArrowCounterclockwise size={18} />
    </button>

    <button 
      className={`btn ${isPlaying ? 'btn-primary' : 'btn-light'} rounded-circle d-flex align-items-center justify-content-center p-2`}
      onClick={togglePlay}
      disabled={!canPlay}
    >
      {isPlaying ? <PauseFill size={24} /> : <PlayFill size={24} />}
    </button>

    <button 
      className="btn btn-light rounded-circle d-flex align-items-center justify-content-center p-2"
      onClick={skipForward}
      disabled={!canPlay}
    >
      <ArrowClockwise size={18} />
    </button>

    <div 
      className="progress-bar" 
      onClick={handleProgress}
      style={{ cursor: canPlay ? 'pointer' : 'default' }}
    >
      <div 
        className="progress-bar-fill" 
        style={{ width: `${(currentTime / duration) * 100 || 0}%` }}
      />
      <div 
        className="progress-handle"
        style={{ left: `${(currentTime / duration) * 100 || 0}%` }}
      />
    </div>

    <button 
      className="btn btn-light d-flex align-items-center px-2 py-1"
      onClick={handlePlaybackRate}
      disabled={!canPlay}
    >
      {playbackRate}×
    </button>

    <button 
      className="btn btn-light d-flex align-items-center justify-content-center p-2"
      onClick={handleDownload}
      title="Download audio"
    >
      <Download size={18} />
    </button>
  </div>
);

const AudioPlayer = ({ audioUrl }) => {
  const [playerState, setPlayerState] = useState({
    isPlaying: false,
    duration: 0,
    currentTime: 0,
    playbackRate: 1,
    error: null,
    canPlay: false
  });
  const audioRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio || !audioUrl) return;

    // Reset states when URL changes
    setPlayerState(prev => ({
      ...prev,
      isPlaying: false,
      currentTime: 0,
      error: null,
      canPlay: false
    }));

    const handlers = {
      loadeddata: () => setPlayerState(prev => ({ ...prev, duration: audio.duration })),
      timeupdate: () => setPlayerState(prev => ({ ...prev, currentTime: audio.currentTime })),
      ended: () => setPlayerState(prev => ({ ...prev, isPlaying: false })),
      canplay: () => setPlayerState(prev => ({ ...prev, canPlay: true, error: null })),
      error: (e) => {
        console.error('Audio error:', e, audio.error);
        const errorMessages = {
          1: 'Audio loading was aborted',
          2: 'Network error occurred',
          3: 'Audio decoding failed',
          4: 'Audio format not supported'
        };
        setPlayerState(prev => ({ 
          ...prev, 
          error: errorMessages[audio.error?.code] || 'Unable to play audio',
          isPlaying: false,
          canPlay: false
        }));
      }
    };

    // Add event listeners
    Object.entries(handlers).forEach(([event, handler]) => {
      audio.addEventListener(event, handler);
    });

    return () => {
      // Remove event listeners
      Object.entries(handlers).forEach(([event, handler]) => {
        audio.removeEventListener(event, handler);
      });
    };
  }, [audioUrl]);

  // Player control handlers
  const controls = {
    togglePlay: () => {
      if (playerState.isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play().catch(console.error);
      }
      setPlayerState(prev => ({ ...prev, isPlaying: !prev.isPlaying }));
    },

    handleProgress: (e) => {
      try {
        const rect = e.currentTarget.getBoundingClientRect();
        const percent = (e.clientX - rect.left) / rect.width;
        
        if (playerState.duration && isFinite(playerState.duration) && isFinite(percent)) {
          const newTime = Math.max(0, Math.min(playerState.duration, percent * playerState.duration));
          
          if (isFinite(newTime)) {
            audioRef.current.currentTime = newTime;
            setPlayerState(prev => ({ ...prev, currentTime: newTime }));
          }
        }
      } catch (error) {
        console.warn('Could not update audio position:', error);
      }
    },

    handlePlaybackRate: () => {
      const rates = [1, 1.5, 2, 0.5];
      const nextRate = rates[(rates.indexOf(playerState.playbackRate) + 1) % rates.length];
      audioRef.current.playbackRate = nextRate;
      setPlayerState(prev => ({ ...prev, playbackRate: nextRate }));
    },

    skipBackward: () => {
      audioRef.current.currentTime = Math.max(0, playerState.currentTime - 10);
    },

    skipForward: () => {
      audioRef.current.currentTime = Math.min(playerState.duration, playerState.currentTime + 10);
    },

    handleDownload: () => {
      if (audioUrl) {
        window.open(audioUrl, '_blank', 'noopener,noreferrer');
      }
    }
  };

  if (!audioUrl) {
    return (
      <div className="audio-player-container">
        <div className="text-muted">No audio recording available</div>
      </div>
    );
  }

  return (
    <div className="audio-player-container">
      <audio 
        ref={audioRef} 
        src={audioUrl}
        preload="auto"
      />
      
      {playerState.error ? (
        <div className="d-flex align-items-center justify-content-between w-100">
          <span className="text-danger">{playerState.error}</span>
          <button 
            className="btn btn-light d-flex align-items-center justify-content-center p-2"
            onClick={controls.handleDownload}
            title="Download audio"
          >
            <Download size={18} />
          </button>
        </div>
      ) : (
        <AudioControls 
          {...playerState}
          {...controls}
        />
      )}
    </div>
  );
};

// Main component
const PresalesEditor = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    getSession,
    updateSession,
    deleteSession,
    archiveSession,
    retryTranscription,
    retryAnalysis,
    loading,
    error,
    triggerSessionReady
  } = useContext(PresalesContext);
  const { prompts, loading: promptsLoading } = useContext(PromptContext);

  const [sessionData, setSessionData] = useState({
    sessionSource: '',
    sessionDestination: '',
    sessionDuration: '',
    promptId: '',
    isTest: false,
    audioUrl: '',
    analysisUrl: '',
  });
  const [analysisContent, setAnalysisContent] = useState('');
  const [transcriptContent, setTranscriptContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSessionData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  useEffect(() => {
    const loadSession = async () => {
      try {
        console.log('Loading session with ID:', id);
        const data = await getSession(id);
        console.log('Session data loaded:', data);
        setSessionData(data || {
          sessionSource: '',
          sessionDestination: '',
          sessionDuration: '',
          promptId: '',
          isTest: false,
          audioUrl: '',
          analysisUrl: '',
        });

        if (data?.analysisUrl) {
          const analysisResponse = await axios.get(data.analysisUrl);
          setAnalysisContent(analysisResponse.data);
        }

        if (data?.transcriptUrl) {
          const transcriptResponse = await axios.get(data.transcriptUrl);
          setTranscriptContent(transcriptResponse.data);
        }
      } catch (err) {
        console.error('Error loading session:', err);
      } finally {
        setIsLoading(false);
      }
    };

    loadSession();
  }, [id, getSession]);

  const handleBack = () => {
    navigate('/presales');
  };

  const handleDelete = async () => {
    if (window.confirm(t('confirmDeleteSession'))) {
      await deleteSession(id);
      navigate('/presales');
    }
  };

  const handleArchive = async () => {
    if (window.confirm(t('confirmArchiveSession'))) {
      await archiveSession(id);
      navigate('/presales');
    }
  };

  const handleTriggerReady = async () => {
    if (window.confirm(t('confirmTriggerSessionReady'))) {
      await triggerSessionReady(id);
    }
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">{t('loading')}</span>
        </div>
      </div>
    );
  }

  return (
    <div id="PresalesEditor">
      <div className="editor-container">
        <div className="editor-main">
          <div className="editor-header">
            <div>
              <div className="meeting-title-row">
                <h1 className="meeting-title">Meeting Title</h1>
                <button 
                  className="btn btn-light close-button" 
                  onClick={handleBack}
                  title={t('cancel')}
                >
                  ×
                </button>
              </div>
              <div className="meeting-meta">
                <span>{sessionData?.sessionSource}</span>
                <span>•</span>
                <span>{new Date(sessionData?.createdAt?._seconds * 1000).toLocaleString()}</span>
              </div>
            </div>
          </div>

          <div className="editor-content">
            <div className="form-section mb-4">
              <form className="row g-3">
                <div className="col-md-6">
                  <label htmlFor="sessionSource" className="form-label">
                    {t('sessionSource')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="sessionSource"
                    name="sessionSource"
                    value={sessionData?.sessionSource || ''}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="sessionDestination" className="form-label">
                    {t('sessionDestination')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="sessionDestination"
                    name="sessionDestination"
                    value={sessionData?.sessionDestination || ''}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="sessionDuration" className="form-label">
                    {t('sessionDuration')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="sessionDuration"
                    name="sessionDuration"
                    value={sessionData?.sessionDuration || ''}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="promptId" className="form-label">
                    {t('aiPrompt')}
                  </label>
                  {promptsLoading ? (
                    <div className="form-control bg-light">{t('loading')}...</div>
                  ) : (
                    <select
                      className="form-select"
                      id="promptId"
                      name="promptId"
                      value={sessionData?.promptId || ''}
                      onChange={handleChange}
                    >
                      <option value="">{t('selectPrompt')}</option>
                      {prompts.map((prompt) => (
                        <option key={prompt.id} value={prompt.id}>
                          {prompt.title}
                        </option>
                      ))}
                    </select>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="isTest"
                      name="isTest"
                      checked={sessionData?.isTest || false}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="isTest">
                      {t('isTest')}
                    </label>
                  </div>
                </div>
              </form>
            </div>

            <hr className="form-divider" />

            <div className="analysis-content">
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
              <ReactMarkdown>{analysisContent}</ReactMarkdown>
            </div>
          </div>
        </div>

        <div className="transcript-panel">
          <div className="transcript-header">
            {t('transcript')}
          </div>
          <div className="transcript-content">
            {transcriptContent && transcriptContent.length > 0 ? (
              transcriptContent.map((item, index) => (
                <div key={index} className="transcript-item">
                  <div className="transcript-speaker">{item.speaker}</div>
                  <div className="transcript-text">{item.text}</div>
                  <div className="transcript-timestamp">{item.timestamp}</div>
                </div>
              ))
            ) : (
              <div className="transcript-empty">
                {t('noTranscriptionDataFound')}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="editor-footer">
        <div className="d-flex align-items-center justify-content-between">
          <div className="flex-grow-1 d-flex justify-content-center">
            <AudioPlayer audioUrl={sessionData?.audioUrl} />
          </div>

          <div className="d-flex gap-2">
            <button
              className="btn btn-light d-flex align-items-center justify-content-center"
              onClick={async () => {
                await updateSession(id, sessionData);
                navigate('/presales');
              }}
              title={t('save')}
            >
              <Save size={18} />
            </button>
            <button
              className="btn btn-light d-flex align-items-center justify-content-center"
              onClick={handleArchive}
              title={t('archive')}
            >
              <Archive size={18} />
            </button>
            <button
              className="btn btn-light d-flex align-items-center justify-content-center text-danger"
              onClick={handleDelete}
              title={t('delete')}
              style={{ display: 'none' }}
            >
              <Trash size={18} />
            </button>
            <button
              className="btn btn-warning d-flex align-items-center gap-2"
              onClick={() => retryTranscription(id)}
              title={t('retryTranscription')}
            >
              <ArrowRepeat size={18} />
              <span>{t('retryTranscription')}</span>
            </button>
            <button
              className="btn btn-warning d-flex align-items-center gap-2"
              onClick={() => retryAnalysis(id)}
              title={t('retryAnalysis')}
            >
              <Robot size={18} />
              <span>{t('retryAnalysis')}</span>
            </button>
            <button
              className="btn btn-warning d-flex align-items-center gap-2"
              onClick={handleTriggerReady}
              title={t('triggerSessionReady')}
            >
              <Send size={18} />
              <span>{t('triggerSessionReady')}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresalesEditor;