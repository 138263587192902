import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../config/firebase';
import './AuthPages.css';

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    if (!email) {
      setError(t('pleaseEnterEmail'));
      return;
    }

    setIsLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage(t('passwordResetEmailSent'));
    } catch (error) {
      console.error('Password reset error:', error);
      switch (error.code) {
        case 'auth/user-not-found':
          setError(t('userNotFound'));
          break;
        case 'auth/invalid-email':
          setError(t('invalidEmailFormat'));
          break;
        default:
          setError(t('passwordResetError'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div id="LoginPage" className="d-flex min-vh-100">
      <div className="login-section w-100 w-md-40">
        <Link to="/login" className="back-button text-secondary text-decoration-none d-flex align-items-center">
          <span className="me-2">←</span> {t('backToLogin')}
        </Link>

        <span className="logo-text d-md-none position-absolute top-0 start-50 translate-middle-x mt-4">
          Selldrive<span className="ai-text">.ai</span>
        </span>

        <div className="login-container">
          <h2 className="mb-4">{t('resetPassword')}</h2>
          <p className="text-muted mb-4">{t('resetPasswordDescription')}</p>

          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          {message && (
            <div className="alert alert-success" role="alert">
              {message}
            </div>
          )}

          <form onSubmit={handlePasswordReset}>
            <div className="mb-4">
              <label htmlFor="email" className="form-label">{t('emailAddress')}</label>
              <input
                type="email"
                id="email"
                className="form-control"
                placeholder={t('enterYourEmail')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isLoading}
                required
              />
            </div>

            <button
              type="submit"
              className="btn btn-primary w-100"
              disabled={isLoading}
            >
              {isLoading ? t('sending') : t('sendResetLink')}
            </button>

            <div className="text-center mt-4">
              <Link to="/login" className="text-decoration-none">
                {t('backToLogin')}
              </Link>
            </div>
          </form>
        </div>
      </div>

      <div className="marketing-section d-none d-md-flex w-md-60">
        <div className="marketing-content">
          <h1 className="marketing-title">{t('welcomeTo')}</h1>
          <div className="marketing-hashtag">{t('withSelldrive')}</div>
          <p className="marketing-description">{t('marketingMessage')}</p>
          <p className="marketing-stats">{t('trustedBy')}</p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
