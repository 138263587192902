import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../config/firebase';
import './LoginPage.css';

function LoginPage() {
  const { t } = useTranslation();
  const { signInWithGoogle } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = () => {
    if (!email || !password) {
      setError(t('pleaseEnterEmailAndPassword'));
      return false;
    }
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError(t('invalidEmailFormat'));
      return false;
    }

    if (password.length < 6) {
      setError(t('passwordTooShort'));
      return false;
    }

    return true;
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setError('');
    
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/sales');
    } catch (error) {
      console.error('Login error:', error);
      switch (error.code) {
        case 'auth/user-not-found':
          setError(t('userNotFound'));
          break;
        case 'auth/wrong-password':
          setError(t('wrongPassword'));
          break;
        case 'auth/too-many-requests':
          setError(t('tooManyAttempts'));
          break;
        default:
          setError(t('signInError'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    setError('');
    setIsLoading(true);
    try {
      await signInWithGoogle();
      navigate('/sales');
    } catch (error) {
      console.error('Google sign-in error:', error);
      setError(t('googleSignInError'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div id="LoginPage" className="d-flex min-vh-100">
      <div className="login-section w-100 w-md-40">
        <a href="https://www.startflow.ai" className="back-button text-secondary text-decoration-none d-flex align-items-center">
          <span className="me-2">←</span> {t('back')}
        </a>
        
        <span className="logo-text d-md-none position-absolute top-0 start-50 translate-middle-x mt-4">
          Selldrive<span className="ai-text">.ai</span>
        </span>
        
        <div className="login-container">
          <h2 className="mb-4">{t('signIn')}</h2>
          
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          <form onSubmit={handleSignIn} className="mb-4">
            <div className="mb-3">
              <label htmlFor="email" className="form-label">{t('emailAddress')}</label>
              <input
                type="email"
                id="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isLoading}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="password" className="form-label">{t('password')}</label>
              <input
                type="password"
                id="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={isLoading}
              />
            </div>

            <button type="submit" className="btn sign-in-button" disabled={isLoading}>
              {isLoading ? t('signingIn') : t('signIn')}
            </button>
          </form>

          <div className="text-center mb-4">
            <button
              className="btn btn-link text-decoration-none p-0 border-0"
              onClick={() => navigate('/register')}
              disabled={isLoading}
            >
              {t('register')}
            </button>
          </div>

          <div className="social-login-separator">
            <span className="social-login-separator-text">{t('orSignInWith')}</span>
          </div>

          <button
            className="social-login-button"
            onClick={handleGoogleSignIn}
            disabled={isLoading}
          >
            <img src="/google-icon.png" alt="" width="20" height="20" />
            {t('signInWithGoogle')}
          </button>

          <button className="social-login-button" disabled={isLoading}>
            <img src="/facebook-icon.png" alt="" width="20" height="20" />
            {t('signInWithFacebook')}
          </button>

          <div className="mt-4 text-center">
            <button
              className="btn btn-link text-decoration-none p-0 border-0 text-muted"
              onClick={() => navigate('/forgot-password')}
              disabled={isLoading}
            >
              {t('forgotPassword')}
            </button>
          </div>
        </div>
      </div>

      <div className="marketing-section d-none d-md-flex w-md-60">
        <div className="marketing-content">
          <h1 className="marketing-title">{t('welcomeTo')}</h1>
          <div className="marketing-hashtag">{t('withSelldrive')}</div>
          <p className="marketing-description">
            {t('marketingMessage')}
          </p>
          <p className="marketing-stats">
            {t('trustedBy')}
          </p>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
