import React, { useState } from 'react';
import { Send, Paperclip } from 'react-bootstrap-icons';
import './ChatPage.css';
import { useTranslation } from 'react-i18next';

const ChatPage = () => {
  const { t } = useTranslation();
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (inputMessage.trim()) {
      setMessages([...messages, { text: inputMessage, sender: 'user' }]);
      setInputMessage('');
      // Simulate AI response
      setTimeout(() => {
        setMessages(prev => [...prev, { 
          text: "I'm here to help you with your sales inquiries.", 
          sender: 'ai' 
        }]);
      }, 1000);
    }
  };

  return (
    <div className="chat-container light">
      <div className="chat-messages">
        {messages.length === 0 ? (
          <div className="empty-chat">
            <h1>How can I assist with your sales today?</h1>
            <div className="suggestion-buttons">
              <button className="suggestion-btn">
                Show top performing sales reps
              </button>
              <button className="suggestion-btn">
                Analyze today's prospects
              </button>
              <button className="suggestion-btn">
                Generate sales forecast
              </button>
              <button className="suggestion-btn">
                Review pipeline metrics
              </button>
            </div>
          </div>
        ) : (
          messages.map((message, index) => (
            <div key={index} className={`message ${message.sender}`}>
              <div className="message-content">
                {message.text}
              </div>
            </div>
          ))
        )}
      </div>
      
      <form onSubmit={handleSendMessage} className="chat-input-container">
        <div className="chat-input-wrapper">
          <button type="button" className="attachment-button">
            <Paperclip />
          </button>
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder="Ask about sales, prospects, or performance..."
            className="chat-input"
          />
          <button 
            type="submit" 
            className="send-button"
            disabled={!inputMessage.trim()}
          >
            <Send />
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChatPage; 