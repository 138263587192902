import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Hash, Plus, ChevronLeft } from 'react-bootstrap-icons';
import './PresalesSecondaryNav.css';

const PresalesSecondaryNav = ({ className = '', isMobileOpen, onMobileClose, onBackClick }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <>
      <div 
        className={`sidebar-backdrop ${isMobileOpen ? 'show' : ''}`}
        onClick={onMobileClose}
      />
      <div className={`secondary-nav ${isMobileOpen ? 'mobile-open' : ''} ${className}`}>
        <div className="secondary-nav-header">
          <button 
            className="back-button d-lg-none me-1"
            onClick={onBackClick}
          >
            <ChevronLeft size={24} />
          </button>
          <h1 className="notebook-title mb-0">Notebook</h1>
        </div>

        <nav className="secondary-nav-content">
          <div className="nav-section">
            <Link 
              to="/presales/dashboard" 
              className={`secondary-nav-link ${isActive('/presales/dashboard') ? 'active' : ''}`}
            >
              <Hash className="nav-icon" />
              <span>Dashboard</span>
            </Link>

            <Link 
              to="/presales/my-meetings" 
              className={`secondary-nav-link ${isActive('/presales/my-meetings') ? 'active' : ''}`}
            >
              <Hash className="nav-icon" />
              <span>My Meetings</span>
            </Link>

            <Link 
              to="/presales/all-meetings" 
              className={`secondary-nav-link ${isActive('/presales/all-meetings') ? 'active' : ''}`}
            >
              <Hash className="nav-icon" />
              <span>All Meetings</span>
            </Link>

            <Link 
              to="/presales/shared" 
              className={`secondary-nav-link ${isActive('/presales/shared') ? 'active' : ''}`}
            >
              <Hash className="nav-icon" />
              <span>Shared With Me</span>
            </Link>
          </div>

          <div className="channels-section">
            <div className="section-header">
              <span>CHANNELS</span>
              <button className="add-channel-btn">
                <Plus size={16} />
              </button>
            </div>
            <div className="channels-placeholder">
              <Hash className="nav-icon" />
              <span>Create channels to organize conversations</span>
            </div>
            <button className="create-channel-btn">
              <Plus size={16} className="me-2" />
              Create
            </button>
          </div>
        </nav>
      </div>
    </>
  );
};

export default PresalesSecondaryNav; 