import React from 'react';
import { useTranslation } from 'react-i18next';
import PresalesSecondaryNav from './PresalesSecondaryNav';
import './PresalesDashboard.css';

const PresalesDashboard = () => {
  const { t } = useTranslation();

  return (
    <div id="PresalesDashboard" className="d-flex h-100">
      <PresalesSecondaryNav />
      <div className="presales-content container-fluid p-4">
        <div className="row">
          <div className="col-12">
            <h2 className="mb-4">Dashboard</h2>
            <div className="dashboard-iframe-container">
              <iframe 
                src="https://lookerstudio.google.com/embed/reporting/99e7b555-7380-4c71-affe-ca1eaa9bde79/page/VgD" 
                frameBorder="0" 
                style={{ border: 0 }} 
                allowFullScreen
                sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresalesDashboard; 