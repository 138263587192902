import React, { createContext, useContext, useState, useRef } from 'react';

const MicrophoneContext = createContext();

export const useMicrophone = () => useContext(MicrophoneContext);

// Helper functions
const getAudioConstraints = () => ({
  channelCount: 1,
  echoCancellation: true,
  autoGainControl: true,
  noiseSuppression: true,
});

const setupMediaStream = async (getMediaFunction, constraints) => {
  try {
    const stream = await getMediaFunction(constraints);
    return stream;
  } catch (error) {
    console.error(`Error accessing media:`, error);
    throw error;
  }
};

const setupAudioMixer = (audioContext, screenStream, micStream) => {
  const destination = audioContext.createMediaStreamDestination();
  const screenAudioSource = audioContext.createMediaStreamSource(
    new MediaStream(screenStream.getAudioTracks())
  );
  const micAudioSource = audioContext.createMediaStreamSource(
    new MediaStream(micStream.getAudioTracks())
  );

  const channelMerger = audioContext.createChannelMerger(2);
  screenAudioSource.connect(channelMerger, 0, 0);
  micAudioSource.connect(channelMerger, 0, 1);
  channelMerger.connect(destination);

  return destination;
};

export const MicrophoneProvider = ({ children }) => {
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [micStream, setMicStream] = useState(null);
  const [screenStream, setScreenStream] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  
  const audioDataCallbackRef = useRef(null);
  const onStopCallbackRef = useRef(null);

  const setAudioDataCallback = (callback) => {
    audioDataCallbackRef.current = callback;
  };

  const setOnStopCallback = (callback) => {
    onStopCallbackRef.current = callback;
  };

  const startRecording = async () => {
    try {
      // Get media streams
      const screenStream = await setupMediaStream(
        navigator.mediaDevices.getDisplayMedia.bind(navigator.mediaDevices),
        { audio: getAudioConstraints(), video: true }
      );
      setScreenStream(screenStream);

      const micStream = await setupMediaStream(
        navigator.mediaDevices.getUserMedia.bind(navigator.mediaDevices),
        { audio: getAudioConstraints(), video: false }
      );
      setMicStream(micStream);

      // Setup audio mixing
      const audioContext = new AudioContext();
      const destination = setupAudioMixer(audioContext, screenStream, micStream);

      // Create and configure MediaRecorder
      const recorder = new MediaRecorder(destination.stream, {
        mimeType: 'audio/webm;codecs=opus',
      });

      configureRecorder(recorder);
      
      setMediaRecorder(recorder);
      setIsRecording(true);
      recorder.start(3000);
    } catch (error) {
      console.error('Error in startRecording:', error);
      throw error;
    }
  };

  const configureRecorder = (recorder) => {
    recorder.ondataavailable = (event) => {
      if (event.data.size > 0 && audioDataCallbackRef.current) {
        audioDataCallbackRef.current(event.data);
      }
    };

    recorder.onerror = (event) => {
      console.error(`Recording error: ${event.error.name}`, event.error);
    };

    recorder.onstop = () => {
      setIsRecording(false);
      if (onStopCallbackRef.current) {
        onStopCallbackRef.current();
      }
    };
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      console.log('Requesting data from MediaRecorder');
      mediaRecorder.requestData();
      
      setTimeout(() => {
        mediaRecorder.stop();
        
        if (screenStream) {
          screenStream.getTracks().forEach((track) => track.stop());
        }
        if (micStream) {
          micStream.getTracks().forEach((track) => track.stop());
        }
        setIsRecording(false);
      }, 100);
    } else {
      setIsRecording(false);
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.pause();
    }
  };

  const muteMicrophone = (mute) => {
    if (micStream) {
      micStream.getAudioTracks().forEach((track) => {
        track.enabled = !mute;
      });
    }
  };

  return (
    <MicrophoneContext.Provider
      value={{
        startRecording,
        stopRecording,
        pauseRecording,
        isRecording,
        muteMicrophone,
        setAudioDataCallback,
        setOnStopCallback,
      }}
    >
      {children}
    </MicrophoneContext.Provider>
  );
}; 